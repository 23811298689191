import { fetchJSON } from '../utils';

class CreateOrderError extends Error {
  constructor(message) {
    super(message);
    this.code = 'CreateOrderError';
  }
}

export async function createOrder(
  { method, price, customerDetails, countryCode, paypalSubscriptionID = null },
  { stripePaymentMethod = null, stripeCardName = null } = {}
) {
  const { firstName, lastName, email, address1, address2, address3, town, county, postCode, whereHeard, password, CompanionFirstName,
    CompanionLastName,
    CompanionEmail } = customerDetails;

  let InvitationData
  if (customerDetails.EmailSubStatus) {
    InvitationData = {
      InviteCode: customerDetails.InviteCode,
      EmailSubStatus: customerDetails.EmailSubStatus,
      newCourse: customerDetails.new_course,
      newEmail: customerDetails.new_email
    }
  }
  let consentData = null
  if (customerDetails.consentData) {
    consentData = customerDetails.consentData
  }

  let language = localStorage.getItem("enrol-app-locale")
  const hearth_wordpress_user_id = localStorage.getItem("hearth-online-user_id")

  try {
    const { data: order, stripe_client_secret_id: stripeClientSecretID, companion_payment_intent: companion_payment_intent } = await fetchJSON('/api/v1/orders', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        method,
        price,
        customer: {
          first_name: firstName,
          last_name: lastName,
          email,
          wordpress_password: password,
          address1,
          address2,
          address3,
          town,
          county,
          post_code: postCode,
          where_heard: whereHeard,
          CompanionFirstName: CompanionFirstName,
          CompanionLastName: CompanionLastName,
          CompanionEmail: CompanionEmail,
          language: language,
          consentData: consentData,
          InvitationData,
          hearth_wordpress_user_id
        },
        country_code: countryCode,
        stripe_payment_method: stripePaymentMethod,
        stripe_card_name: stripeCardName,
        pay_pal_subscription_id: paypalSubscriptionID
      })
    });

    if (price.value != 0) {
      return { order, stripeClientSecretID, };
    } else {
      return { order };
    }
  } catch (error) {
    throw new CreateOrderError(`Failed to create order: ${error.message}`);
  }
}

export function paymentMethods() {
  return [
    "card",
    "ideal",
    "sepa_debit",
    "bancontact",
    "eps",
    "giropay",
    "p24",
  ]
}
