<script>
  export let visible, error, region;
  import { getErrorReason } from "../../payment/stripe.js";
  import { _ } from "svelte-i18n";

  import Modal from "../Modal.svelte";

  let description = "",
    advice = "",
    code;
  $: {
    if (error) {
      const reason = getErrorReason(error);
      code = error.code;
      description = reason.description;
      advice =
        typeof reason.advice === "object"
          ? reason.advice[region.code]
          : reason.advice;
    } else {
      description = advice = "";
    }
  }

  function hide() {
    visible = false;
  }
</script>

<Modal bind:visible>
  <h1>{$_("payment.Sorry, your payment didn't go through")}.</h1>
  {#if code === "card_declined"}
    <p class="stripe-decline-description">{description || ""}</p>
    <p class="stripe-decline-advice">{advice || ""}</p>
  {:else}
    <p class="stripe-decline-description">{$_(description) || ""}</p>
    <p class="stripe-decline-advice">{$_(advice || "")}</p>
  {/if}
  <button
    on:click|preventDefault={hide}
    class="btn btn--primary stripe-decline-close">{$_("payment.CLOSE")}</button
  >
</Modal>

<style>
  .stripe-decline-description,
  .stripe-decline-advice {
    margin: 1em 0;
  }

  .stripe-decline-description {
    font-weight: bold;
  }

  .stripe-decline-close {
    width: 100%;
  }
</style>
