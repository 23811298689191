import { createOrder } from './utils';
import { fetchJSON } from '../utils';
import { errorReasons, declineReasons } from './stripe-errors.js';
import { _ } from "svelte-i18n"

export function getErrorReason(error) {
  const { code } = error;
  if (code === 'card_declined') {
    return declineReasons[error.decline_code] || declineReasons.default;
  } else {
    return errorReasons[code] || errorReasons.default;
  }
}

/**
 * Doesn't matter if this works or not.
 */
export async function confirmStripePayment({ id }, params = {}) {
  fetchJSON(`/api/v1/orders/${id}`, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(params)
  });
}

export async function stripePayment(stripe, orderData, name, card) {
  const { customerDetails: { address1: line1, address2: line2, email, town: city, county: state, postCode }, price, countryCode } = orderData;
  const monthly = price.variant.payment_frequency === 'monthly';
  const annually = price.variant.payment_frequency === 'annually';

  const paymentMethodData = {
    type: 'card',
    card,
    billing_details: {
      name,
      email,
      address: { line1, line2, city, state, postal_code: postCode, country: countryCode }
    }
  };

  // Add stripe to order data.
  orderData = Object.assign({}, orderData, { method: 'stripe' });

  // For monthly / annually payments, pass the payment method info to the server.
  let response;
  if (monthly || annually) {
    const { paymentMethod, error } = await stripe.createPaymentMethod(paymentMethodData);
    if (error) {
      return { error };
    } else {
      response = await createOrder(orderData, { stripePaymentMethod: paymentMethod, stripeCardName: name });
    }
  } else {
    response = await createOrder(orderData);
  }

  if (price.value == 0) {
    return response;
  }

  // If we received a client secret from the server, use it to confirm payment.
  const { order, stripeClientSecretID } = response;
  if (stripeClientSecretID) {
    const { error } = await stripe.confirmCardPayment(stripeClientSecretID, { payment_method: paymentMethodData });
    if (error) {
      return { error };
    } else {
      await confirmStripePayment(order);
      return { order };
    }
  } else {
    // No confirmation required.  All done.
    return { order };
  }
}
