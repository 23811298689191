<script>
  export let visible = false;
  import Overlay from "./Overlay.svelte";

  let modalElement;
  $: {
    if (visible && modalElement) {
      modalElement.focus();
    }
  }
</script>

<Overlay
  {visible}
  onEscape={() => (visible = false)}
  onClick={() => (visible = false)}
>
  <div class="modal" bind:this={modalElement}>
    <slot />
  </div>
</Overlay>

<style>
  @import "../styles/variables.css";

  .modal {
    background: white;
    padding: 1rem;
    max-width: 43rem;
    width: calc(100% - 2rem);
    border-radius: 5px;
    box-shadow: 0 0 5px black;

    position: relative;
    z-index: var(--overlay-fg-layer);
  }
</style>
