export const declineReasons = {
  authentication_required: {
    description: 'The card was declined as the transaction requires authentication.',
    advice: 'Please try again and authenticate your card when prompted during the transaction.'
  },
  approve_with_id: {
    description: 'The payment cannot be authorized.',
    advice: 'Please try again. If it still cannot be processed, you many need to contact your card issuer.'
  },
  card_not_supported: {
    description: 'The card does not support this type of purchase.',
    advice: 'If you wish to pay with this card, you will need contact your card issuer to make sure the card can be used to make this type of purchase.'
  },
  card_velocity_exceeded: {
    description: 'You have exceeded the balance or credit limit available on this card.',
    advice: 'Please contact your card issuer for more information.'
  },
  currency_not_supported: {
    description: 'The card does not support the specified currency.',
    advice: 'You will need to check with your card issuer whether to see if this card can be used for the type of currency specified.'
  },
  duplicate_transaction: {
    description: 'A transaction with identical amount and credit card information was submitted very recently.',
    advice: 'Check to see if a recent payment already exists.'
  },
  expired_card: {
    description: 'The card has expired.',
    advice: 'Please try another card.'
  },
  incorrect_number: {
    description: 'The card number is incorrect.',
    advice: 'Please try again using the correct card number.'
  },
  incorrect_cvc: {
    description: 'The CVC number is incorrect.',
    advice: 'Please try again using the correct CVC.'
  },
  incorrect_pin: {
    description: 'The PIN entered is incorrect.',
    advice: 'Please try again using the correct PIN.'
  },
  incorrect_zip: {
    description: 'The ZIP/postal code is incorrect.',
    advice: 'Please try again using the correct billing ZIP/postal code.'
  },
  insufficient_funds: {
    description: 'The card has insufficient funds to complete the purchase.',
    advice: 'Please try using an alternative payment method.'
  },
  invalid_account: {
    description: 'The card, or account the card is connected to, is invalid.',
    advice: 'Please contact your card issuer to check that the card is working correctly.'
  },
  invalid_amount: {
    description: 'The payment amount is invalid, or exceeds the amount that is allowed.',
    advice: 'Please check with your card issuer that you can make purchases of this amount.'
  },
  invalid_cvc: {
    description: 'The CVC number is incorrect.',
    advice: 'Please try again using the correct CVC.'
  },
  invalid_expiry_year: {
    description: 'The expiration year is invalid.',
    advice: 'Please try again using the correct expiration date.'
  },
  invalid_number: {
    description: 'The card number is incorrect.',
    advice: 'Please try again using the correct card number.'
  },
  issuer_not_available: {
    description: 'The card issuer could not be reached, so the payment could not be authorized.',
    advice: 'Please try again. If the payment still cannot be processed, Please contact your card issuer.'
  },
  new_account_information_available: {
    description: 'The card, or account the card is connected to, is invalid.',
    advice: 'Please contact your card issuer for more information.'
  },
  not_permitted: {
    description: 'The payment is not permitted.',
    advice: 'Please contact your card issuer for more information.'
  },
  offline_pin_required: {
    description: 'The card has been declined as it requires a PIN.',
    advice: 'Please try a different card.'
  },
  online_or_offline_pin_required: {
    description: 'The card has been declined as it requires a PIN.',
    advice: 'Please try a different card.'
  },
  pickup_card: {
    description: 'The card cannot be used to make this payment',
    advice: 'Please contact your card issuer for more information.'
  },
  processing_error: {
    description: 'An error occurred while processing the card.',
    advice: 'Please try again. If the payment still cannot be processed, try again later.'
  },
  reenter_transaction: {
    description: 'The payment could not be processed by the issuer for an unknown reason.',
    advice: 'Please try again. If the payment still cannot be processed, please contact your card issuer.'
  },
  testmode_decline: {
    description: 'A Stripe test card number was used.',
    advice: 'A genuine card must be used to make a payment.'
  },
  try_again_later: {
    description: 'The card has been declined for an unknown reason.',
    advice: 'Please try again. If subsequent payments are declined, please contact your card issuer for more information.'
  },
  withdrawal_count_limit_exceeded: {
    description: 'You have exceeded the balance or credit limit available on the card.',
    advice: 'Please use an alternative payment method.'
  },
  default: {
    description: 'Problems have occurred authorising this transaction, possibly due to automatic security / fraud prevention measures in place by your card issuer.',
    advice: {
      GB: 'Please contact your card issuer for more information.',
      ROW: 'Please contact your bank to check that overseas transactions are enabled on this card.'
    }
  }
};

export const errorReasons = Object.assign(Object.create(declineReasons), {
  default: {
    description: 'We failed to charge your card or payment for an unknown reason.',
    advice: 'Please contact your card issuer or bank for more information.'
  }
});
